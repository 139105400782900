import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";

import NavBarLinkItem from "./NavBarLinkItem";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function groupBy(array, f) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  })
}

var navConfig = [
  {
    groupName: "",
    elements: [
      {
        id: 1,
        displayText: "Home",
        url: "/",
        iconName: "fal fa-home"
      }
    ]
  },
  {
    groupName: "Company",
    elements: [
      {
        id: 10,
        displayText: "Posts",
        url: "/company-wellness",
        iconName: "fal fa-bullhorn"
      },
      {
        id: 1,
        displayText: "User Search",
        url: "/user-search",
        iconName: "fal fa-search",
        moduleName: "User Search"
      },
      {
        id: 2,
        displayText: "Chat",
        url: "/chat",
        iconName: "fal fa-comments",
        moduleName: "NewChat"
      },
      {
        id: 3,
        displayText: "Health Benefits",
        url: "/health-benefits",
        iconName: "fal fa-heartbeat",
        moduleName: "Health Benefits"
      },
      {
        id:6,
        displayText: "Wellness Library",
        url: "/wellness-library",
        iconName: "fal fa-book",
        moduleName: "Wellness Library"
      },
      {
        id: 5,
        displayText: "Webinars",
        url: "/meetings",
        iconName: "fal fa-video",
        moduleName: "Video Chat"
      },
      {
        id: 4,
        displayText: "Organisation Reports",
        url: "/hr-reports",
        iconName: "fal fa-clipboard-list-check",
        moduleName: "Organisation Reports"
      }
    ]
  },
  {
    groupName: "Mental Health",
    elements: [
      {
        id: 1,
        displayText: "Yoga",
        url: "/fitness-library?direction=yoga",
        iconName: "fal fa-spa",
        moduleName: "Fitness Library"
      },
      {
        id: 2,
        displayText: "Mental Health Library",
        url: "/wellness-library?direction=mental-health-library",
        iconName: "fal fa-book",
        moduleName: "Wellness Library"
      }
      // {
      //   id: 3,
      //   displayText: "Breathwork",
      //   url: "/breathing-practices",
      //   iconName: "fal fa-wind",
      //   moduleName: "Wellness Library"
      // }
    ]
  },
  {
    groupName: "Financial Health",
    elements: [
      {
        id: 3,
        displayText: "Finance Calculators",
        url: "/finance-calculators",
        iconName: "fal fa-money-bill",
        moduleName: "Health Store"
      },
      {
        id: 1,
        displayText: "Health Store",
        url: "/health-store",
        iconName: "fal fa-laptop-medical",
        moduleName: "Health Store"
      },
      {
        id: 2,
        displayText: "Financial Health Library",
        url: "/wellness-library?direction=financial-health-library",
        iconName: "fal fa-book",
        moduleName: "Wellness Library"
      }
    ]
  },
  {
    groupName: "Physical Health",
    elements: [
      {
        id: 1,
        displayText: "Food Diary",
        url: "/nutrition/diary",
        iconName: "fal fa-book-heart",
        moduleName: "Diary"
      },
      {
        id: 2,
        displayText: "Meals",
        url: "/nutrition/meals",
        iconName: "fal fa-utensils-alt",
        moduleName: "Meals"

      },
      {
        id: 2,
        displayText: "Fitness Library",
        url: "/fitness-library",
        iconName: "fal fa-dumbbell",
        moduleName: "Fitness Library"
      },
      {
        id: 3,
        displayText: "Health Check",
        url: "/health-check",
        iconName: "fal fa-user-md",
        moduleName: "Health Check"
      },
      {
        id: 4,
        displayText: "Health Stats",
        url: "/health-assessment",
        iconName: "fal fa-chart-line",
        moduleName: "Health Check"
      },
      {
        id: 5,
        displayText: "Meal Plans",
        url: "/nutrition/meal-plans",
        iconName: "fal fa-turkey",
        moduleName: "Meal Plans"
      },
      {
        id: 6,
        displayText: "Meal Resources",
        url: "/nutrition/meal-resources",
        iconName: "fal fa-books",
        moduleName: "Meal Resources"
      },
      {
        id: 7,
        displayText: "Meal Builders",
        url: "/nutrition/meal-builders",
        iconName: "fal fa-hat-chef",
        moduleName: "Meal Builders"

      },
      {
        id: 1,
        displayText: "Physical Health Library",
        url: "/wellness-library?direction=physical-health-library",
        iconName: "fal fa-book",
        moduleName: "Wellness Library"
      },

    ]
  },
  {
    groupName: "Social Health",
    elements: [
      {
        id: 1,
        displayText: "Challenges",
        url: "/challenges",
        iconName: "fal fa-trophy-alt",
        moduleName: "Challenges"
      },
      {
        id: 2,
        displayText: "Chat",
        url: "/chat",
        iconName: "fal fa-comments",
        moduleName: "NewChat"
      },
      {
        id: 1,
        displayText: "Social Health Library",
        url: "/wellness-library?direction=social-health-library",
        iconName: "fal fa-book",
        moduleName: "Wellness Library"
      }
    ]
  },
  {
    groupName: "Settings",
    elements: [
      {
        id: 1,
        displayText: "My Profile",
        url: "/profile",
        iconName: "fal fa-user",
        moduleName: "My Profile"
      },
      {
        id: 2,
        displayText: "My Goals",
        url: "/goals",
        iconName: "fal fa-bullseye-arrow",
        moduleName: "My Goals"
      },
      {
        id: 3,
        displayText: "My App Connections",
        url: "/app-connections",
        iconName: "fal fa-mobile",
        moduleName: "My App Connections"
      },
      {
        id: 4,
        displayText: "My Sources",
        url: "/sources",
        iconName: "fal fa-cloud",
        moduleName: "My Sources"
      },
      {
        id: 5,
        displayText: "Modules",
        url: "/my-modules",
        iconName: "fal fa-cubes",
        moduleName: "Modules"
      }
    ]
  }
]

function NavBar(props) {

  const bodyClass = document.body;
  const companyName = props.companyName;
  navConfig[1].groupName = companyName;

  bodyClass.classList.add("company-" + props.dataStores.APP_CONFIG.companyId);

  const pStyles = props.styles;

  function styles() {
    return {
      backgroundColor: pStyles ? pStyles.navBarBackgroundColor : "#fff",
      color: pStyles ? pStyles.navBarColor : "#fff"
    };
  }
  
  const modules = props.elements.map(e => e.displayText);
  return (
    <div className="ml-2">
    {navConfig.map(g => {
      return <>
        <span className="c-sidebar__title">{g.groupName}</span>
        <ul className="c-sidebar__list">
          {g.elements.map(element => {
            if(!element.url || (element.moduleName && !modules.includes(element.moduleName))){return null};

            const navItemBtnId = element.displayText.replace(/\s+/g, '-').toLowerCase();
            // {/* Is this a single item or is it part of a drop down? */}
            if (!element.children || element.children.length === 0)
              //
              // There are no children so just render out a link
              return (
                <li key={element.id} className={pStyles.navBarColor}>
                  <Link className="c-sidebar__link" to={element.url} id={`nav-${navItemBtnId}`} onClick={props.onSelect}>
                    <i className={`c-sidebar__icon ${element.iconName}`}></i>{element.displayText}</Link>
                </li>
              );
            else {
              // There are children in the menu item, so display it as a drop down

              return (
                <li key={element.id} className={`c-sidebar__item has-submenu ${pStyles.navBarColor}`}>
                  <Link className="c-sidebar__link" data-toggle="collapse">
                    <i className={`c-sidebar__icon ${element.iconName}`}></i>{element.displayText}</Link>
                  <div>
                    <ul className="c-sidebar__list collapse show">
                      {element.children.map(child => {
                        return (
                          <li key={child.id} className={pStyles.navBarColor}>
                            <Link className="c-sidebar__link" to={child.url}>
                              <i className={`c-sidebar__icon ${child.iconName}`}></i>{child.displayText}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </>
    })}
    </div>
  )
  // return (
  //   <>
  //     {
  //       groups.map(g => {
  //         return <>
  //           <span className="c-sidebar__title">{g[0].groupName}</span>
  //           <ul className="c-sidebar__list">
  //             {g.map(element => {
  //               if(!element.url){return null};
  //               const navItemBtnId = element.displayText.replace(/\s+/g, '-').toLowerCase();

  //               // {/* Is this a single item or is it part of a drop down? */}
  //               if (!element.children || element.children.length === 0)
  //                 //
  //                 // There are no children so just render out a link
  //                 return (
  //                   <li key={element.id} className={pStyles.navBarColor}>
  //                     <Link className="c-sidebar__link" to={element.url} id={`nav-${navItemBtnId}`} onClick={props.onSelect}>
  //                       <i className={`c-sidebar__icon ${element.iconName}`}></i>{element.displayText}</Link>
  //                   </li>
  //                 );
  //               else {
  //                 // There are children in the menu item, so display it as a drop down

  //                 return (
  //                   <li key={element.id} className={`c-sidebar__item has-submenu ${pStyles.navBarColor}`}>
  //                     <Link className="c-sidebar__link" data-toggle="collapse">
  //                       <i className={`c-sidebar__icon ${element.iconName}`}></i>{element.displayText}</Link>
  //                     <div>
  //                       <ul className="c-sidebar__list collapse show">
  //                         {element.children.map(child => {
  //                           return (
  //                             <li key={child.id} className={pStyles.navBarColor}>
  //                               <Link className="c-sidebar__link" to={child.url}>
  //                                 <i className={`c-sidebar__icon ${child.iconName}`}></i>{child.displayText}</Link>
  //                             </li>
  //                           );
  //                         })}
  //                       </ul>
  //                     </div>
  //                   </li>
  //                 );
  //               }
  //             })}
  //           </ul>
  //         </>
  //       })
  //     }
  //   </>
  // );
}

function mapStateToProps(state, ownProps) {
  return {
    forDate: ownProps.forDate,
    changeDateByDays: ownProps.changeDateByDays,
    dataStores: state.appObject,
    onSelect: ownProps.onSelect
  };
}

export default connect(mapStateToProps)(NavBar);
