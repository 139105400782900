import React, {useContext, createRef} from 'react';

import { useContentShareState,
    useFeaturedTileState,
    useLocalVideo,
    useRemoteVideoTileState,
    VideoGrid,
    ContentShare,
    LocalVideo,
    useRosterState,
    RemoteVideo,
    RemoteVideos,
    useElementAspectRatio
 } from 'amazon-chime-sdk-component-library-react';
 import { StyledGrid } from './GridDataStyled';
const fluidStyles = `
  height: 100%;
  width: 100%;
`;

const staticStyles = `
  display: flex;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 20vw;
  max-height: 30vh;
  height: auto;
  video {
    position: static;
  }
`;


export const VideoTileGrid = ({
  noRemoteVideoView,
  layout = 'featured',
  attendees,
  ...rest
}) => {
  const { tileId: featureTileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { isVideoEnabled } = useLocalVideo();
  const featured =
    !!featureTileId || !!contentTileId;
  const gridEl = createRef();
  const remoteSize = tiles.length + (contentTileId ? 1 : 0);
  const gridSize =
    remoteSize > 1 && isVideoEnabled ? remoteSize + 1 : remoteSize;
  const ratio = useElementAspectRatio(gridEl);
  

  return (
    <StyledGrid
        ref={gridEl}
        {...rest}
        size={gridSize}
        ratio={ratio}
        featured={featured}
        data-testid="video-grid"
      >
      {/* <VideoGrid {...rest} size={gridSize} layout={featured}> */}
        <ContentShare css={"grid-area: ft;"} nameplate={'Shared Screen'} />
        {featured?(<FeaturedRemoteVideos attendees={attendees} />):(
           <RemoteVideos attendees={attendees} />
        )}
       
        <LocalVideo
          css={gridSize > 1 ? fluidStyles : staticStyles}
          nameplate={'You'}
        />
        {remoteSize === 0 && noRemoteVideoView}
      {/* </VideoGrid> */}
    </StyledGrid>
  );
};


const FeaturedRemoteVideos = (props) => {
    const { roster } = useRosterState();
    const { tileId: featuredTileId } = useFeaturedTileState();
    const { tileId: contentTileId } = useContentShareState();
    const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();
  
    return (
      <>
        {tiles.map((tileId) => {
          const featured = !contentTileId && featuredTileId === tileId;
          const styles = featured? 'grid-area: ft;' : '';
          const classes = `${featured ? 'ch-featured-tile' : ''} ${
            props.className || ''
          }`;
          const attendeeId = tileIdToAttendeeId[tileId];
          var name = props.attendees.find(x=>x.attendeeId===attendeeId)?.senderName;
          if(!name){name='';}
  
          return (
            <RemoteVideo
              tileId={tileId}
              name={name}
              {...props}
              className={classes}
              key={tileId}
              css={styles}
            />
          );
        })}
      </>
    );
  };





export default VideoTileGrid;