import React from "react";
import { useEffect, useState } from "react";
import Meeting from "./Meeting";
import { createHeroApiClient } from "../hero-api-client";
import {
  BackgroundBlurProvider,
  MeetingProvider,
  darkTheme,
  lightTheme,
  UserActivityProvider,
  NotificationProvider,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MeetingForMobile from "./MeetingForMobile";
import CryptoJS from "crypto-js";

const WebViewMeeting = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { meetingId: meetingIdParam, userId: userIdParam, name: nameParam, meeting:meetingParam, ...params } = Object.fromEntries(
    queryParams.entries()
  );
  //set window url to /meetings
  window.history.pushState({}, null, "/meetings");
  const [noLeave, setNoLeave] = useState(false);
  const [meetingId, setMeetingId] = useState(meetingIdParam);
  const [userId, setUserId] = useState(userIdParam);
  const [name, setName] = useState(nameParam);
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState("");
  const [endMeeting, setEndMeeting] = useState(false);

  useEffect(() => {
    const secretKey = 'RRL2cQ7EN6mMrAWRyT43dEFbUnSrduEW';
    if (meetingParam) {
      const unencrypted = CryptoJS.AES.decrypt(meetingParam, secretKey);
      const decrypted = unencrypted.toString(CryptoJS.enc.Utf8);
      const meeting = JSON.parse(decrypted);
      console.log(meeting);
      if(!meeting.meetingId || !meeting.userId || !meeting.name || !meeting.token) {
        return;
      }
      setMeetingId(meeting.meetingId);
      setUserId(meeting.userId);
      setName(meeting.name);
      setToken(meeting.token);
      setNoLeave(true);
    }
  }, [meetingParam]);


  const sendMessage = (message) => {
    try{
      window.ReactNativeWebView.postMessage(JSON.stringify(message), "*");
    }
    catch(err){
      console.log(err);
    }
    
  };

  useEffect(() => {
    window.addEventListener("message", (event) => {
      setMessage(message + JSON.stringify(event));
      let { data } = event;
      if (!data.type) {
        data = JSON.parse(data);
      }
      if (data && data.type === "SET_TOKEN") {
        setToken(data.token);
      } else if (data && data.type === "END_MEETING") {
        setEndMeeting(true);
      }
    });
  }, []);
 
  

  const [meetingDetails, setMeetingDetails] = useState(null);

  useEffect(() => {
    // const newToken = window.localStorage.getItem("token");
    // if (newToken && newToken.length > 10 && token.length < 10) {
    //   setToken(newToken);
    // }
    if (!token || !meetingId || !userId || !name || !token.length) {
      return;
    }
    sendMessage({ type: "SET_MEETING_DETAILS", meetingId, userId, name });
    startMeeting();
  }, [token, meetingId, userId, name]);

  const startMeeting = () => {
    if (token.length < 10) {
      return;
    }

    const heroApiClient = createHeroApiClient(token);
    heroApiClient
      .startMeeting(meetingId)
      .then((meetingDetails) => {
        setMeetingDetails(meetingDetails);
      })
      .catch((err) => {
        sendMessage({ type: "ERROR", message: err });
      });
  };
  if (!token || token.length < 10) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <div style={{ margin: 20 }}>Waiting for authentication token... {token}</div>
        <div>
          {message} {token}
        </div>
        {!noLeave && (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "DISMISS" }),
                  "*"
                );
              }}
            >
              Leave
            </button>)}
      </div>
    );
  }
  if (!meetingId || !meetingId.length) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <div style={{ margin: 20 }}>Waiting for meeting Id from app...</div>
        {!noLeave && (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "DISMISS" }),
                  "*"
                );
              }}
            >
              Leave
            </button>)}
      </div>
    );
  }
  if (!userId || !userId.length) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <div style={{ margin: 20 }}>Waiting for user id from app...</div>
        {!noLeave && (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "DISMISS" }),
                  "*"
                );
              }}
            >
              Leave
            </button>)}
      </div>
    );
  }
  if (!name || !name.length) {
    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <div style={{ margin: 20 }}>Waiting for user name from app...</div>
        {!noLeave && (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "DISMISS" }),
                  "*"
                );
              }}
            >
              Leave
            </button>)}
      </div>
    );
  }
  if (!meetingDetails) {
    return (
      <>
        <div style={{ margin: 20, marginTop: 300 }}>
          Waiting for meeting information. If this takes too long check your
          internet connection.
        </div>
        {/* make buttons centered */}
        <div style={{ textAlign: "center" }}>
          <button onClick={startMeeting}>Retry</button>
          {!noLeave && (
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: "DISMISS" }),
                  "*"
                );
              }}
            >
              Leave
            </button>
          )}
        </div>
      </>
    );
  }
  return (
    <>
      {meetingDetails && (
          <BackgroundBlurProvider>
            <NotificationProvider>
              <UserActivityProvider>
                <ThemeProvider theme={darkTheme}>
                  <MeetingProvider>
                    <MeetingForMobile
                      noLeave={noLeave}
                      dismiss={() => {
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({ type: "DISMISS" }),
                          "*"
                        );
                      }}
                      meetingId={meetingId}
                      userId={userId}
                      name={name}
                      username={name}
                      endMeeting={endMeeting}
                      meetingDetails={meetingDetails}
                      heroApiClient={createHeroApiClient(token)}
                    />
                  </MeetingProvider>
                </ThemeProvider>
              </UserActivityProvider>
            </NotificationProvider>
          </BackgroundBlurProvider>
      )}
    </>
  );
};

export default WebViewMeeting;
