import React, { useState, useEffect, useRef } from "react";
import BreathingCountDownDisplay from "./BreathingCountDownDisplay";
import { select } from "d3";
import { vi } from "date-fns/locale";

const BreathingVideo = ({ videoType, practiceLength }) => {

  const videoRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState("https://cdn.nvgtr.io/v/0c20afa6-8ff9-4f61-91a8-3cae7476e625.mp4");

  const videoList = [
    { id: 1,
        type: "box",
      image: "https://cdn.nvgtr.io/i/6d95488b-817e-4681-ab33-83a57d25b420-Mountain.jpg",
      video: "https://cdn.nvgtr.io/v/0c20afa6-8ff9-4f61-91a8-3cae7476e625.mp4"
    },
    { 
        id: 2,
        type: "box",
        image: "https://cdn.nvgtr.io/i/621c3011-c846-48d7-908e-a8ce289950e5-Lake.jpg",
        video: "https://cdn.nvgtr.io/v/80f9cfc6-215d-47df-a1b6-e61181155ee8.mp4"
    },
    { 
        id: 3,
        type: "box",
        image: "https://cdn.nvgtr.io/i/bbee4205-6765-4b4e-93e8-921555181844-CloudSquare.jpg",
        video: "https://cdn.nvgtr.io/v/a99014c8-4960-41fe-a655-2988fbed24a6.mp4"
    },
    { 
        id: 4,
        type: "478",
        image: "https://cdn.nvgtr.io/i/6d95488b-817e-4681-ab33-83a57d25b420-Mountain.jpg",
        video: "https://cdn.nvgtr.io/v/30226168-9dfe-4fde-bf88-73d15b0d5c38.mp4"
    },
    { 
        id: 5,
        type: "478",
        image: "https://cdn.nvgtr.io/i/621c3011-c846-48d7-908e-a8ce289950e5-Lake.jpg",
        video: "https://cdn.nvgtr.io/v/4bcd65b9-fc65-4b68-8860-c639480add2f.mp4"
    },
    { 
        id: 6,
        type: "478",
        image: "https://cdn.nvgtr.io/i/bbee4205-6765-4b4e-93e8-921555181844-CloudSquare.jpg",
        video: "https://cdn.nvgtr.io/v/26acad72-7014-40bc-9c5b-af4d1a5c4ad5.mp4"
    },
    { 
        id: 7,
        type: "circle",
        image: "https://cdn.nvgtr.io/i/6d95488b-817e-4681-ab33-83a57d25b420-Mountain.jpg",
        video: "https://cdn.nvgtr.io/v/d62865d0-e774-40bf-8277-1d8ae60119d8.mp4"
    },
    { 
        id: 8,
        type: "circle",
        image: "https://cdn.nvgtr.io/i/621c3011-c846-48d7-908e-a8ce289950e5-Lake.jpg",
        video: "https://cdn.nvgtr.io/v/bb8d7b70-625a-4769-ad76-96196f2e21cd.mp4"
    },
    { 
        id: 9,
        type: "circle",
        image: "https://cdn.nvgtr.io/i/bbee4205-6765-4b4e-93e8-921555181844-CloudSquare.jpg",
        video: "https://cdn.nvgtr.io/v/ba82fe13-0c22-45a0-9dde-1fb419aca5e3.mp4"
    }
];

  //if selectedVdeo changes refesh video
  useEffect(() => {
    const resumeVideo = !videoRef.current.paused;
    const currentTime = videoRef.current.currentTime;
    videoRef.current.load();
    if (resumeVideo) {
      videoRef.current.currentTime = currentTime;
      videoRef.current.play();
    }
  }, [selectedVideo]);

  useEffect(() => {
    setSelectedVideo(getRandomVideoByType(videoType));
    }, [videoType]);

  const getRandomVideoByType = (type) => {
    const filteredVideos = videoList.filter((video) => video.type === type);
    const randomVideo = filteredVideos[Math.floor(Math.random() * filteredVideos.length)];
    return randomVideo.video;
  }

  const getVideoSelectionByType = (type) => {
    const filteredVideos = videoList.filter((video) => video.type === type);
  
    return (
      <div>
        {filteredVideos.map((video) => (
          <img
            key={video.id}
            src={video.image}
            alt={`Video ${video.id}`}
            style={{
              width: '8%',
              cursor: 'pointer',
              margin: '5px',
              borderRadius: '8px', // Adjust the value as needed for the desired roundness
              opacity: '0.5',     // Initial opacity
              transition: 'opacity 0.3s', // Smooth transition effect
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '1';
            }}
            onMouseLeave={(e) => {
              e.target.style.opacity = '0.5';
            }}
            onClick={() => { setSelectedVideo(video.video) }}
          />
        ))}
      </div>
    );
  };
  

  const pauseVideo = () => {
    videoRef.current.pause();
  };

  const startVideo = () => {
    videoRef.current.play();
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div style={{ position: 'relative', width: '100%', height: '50%' }}>
        <video width="100%" height="100%" loop ref={videoRef}>
          <source src={selectedVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={{ position: 'absolute', bottom: 5, right: 5 }}>
          {getVideoSelectionByType(videoType)}
        </div>
      </div>
      {/* <BreathingCountDownDisplay practiceLength={practiceLength} pauseVideo={pauseVideo} startVideo={startVideo} /> */}
    </div>
  );
  
};

export default BreathingVideo;
