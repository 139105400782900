import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import Login from "./pages/login.jsx";
import Calendar from "./pages/calendar";
import { Help } from "./pages/help";
import { AccountHelp } from "./pages/AccountHelp";
import Dashboard from "./pages/dashboard";
import HealthCheck from "./pages/health-check";
import HealthAssessment from "./pages/health-assessment";
import HealthCalendar from "./pages/health-calendar"
import MealDiary from "./pages/Nutrition/food-diary";
import MealCalendar from "./pages/Nutrition/meal-calendar";
import MyMeals from "./pages/Nutrition/meals";
import NewStyle from "./pages/newStyle";
import Challenges from "./pages/challenges";
import Challenge from "./pages/challenge";
import Article from "./pages/Article";
import Chat from "./pages/chat";
import ChatFinder from "./pages/chatfinder"
import Video from "./pages/meetings"
import MealDisplay from "./pages/Nutrition/meal-display";
import StoryblokContentRoot from "./components/storyblok/StoryblokContentRoot";
import StoryblokStory from "./components/storyblok/StoryblokStory";
import Groups from "./pages/groups";
import Group from "./pages/group";
import Reports from "./pages/reports";
import HrReports from "./pages/hr-reports";
import HrUserManagement from "./pages/hr-user-management";
import Profile from "./pages/profile";
import Coach from "./pages/coach";
import Goals from "./pages/goals";
import Sources from "./pages/sources";
import AppConnections from "./pages/app-connections";
import SignUp from "./pages/signup";
import Landing from "./pages/Landing";
import Modules from './pages/modules';
import { connect } from "react-redux";
import dataStores from "./redux/reducers/dataStores";
import "./App.scss";
import "./styles";
import { format, addDays } from "date-fns";
import Notifications from './components/notifications/Notifications';
import "nprogress/nprogress.css";
import UserManagement from "./pages/user-management";
import store from "./redux/store";
import storage from './shared/storage';
import {createHeroApiClient, EngagementEvent} from "./hero-api-client";
import {beginUserSession} from "./redux/actions/userSession";
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import UserSearch from "./pages/UserSearch";
import GuestMeeting from "./pages/guestMeeting";
import WebViewMeeting from "./pages/WebViewMeeting";
import BreathingPractices from "./pages/BreathingPractices.jsx";
import FinanceCalculators from "./pages/FinanceCalculators.jsx";
import SavingsCalculator from "./pages/Calculators/SavingsCalculator.jsx";
import LoanCalculator from "./pages/Calculators/LoanCalculator.jsx";
import MortgageCalculator from "./pages/Calculators/MortgageCalculator.jsx";

let genericEngagementElements = [];
let genericEngagementTimeout = null;

let touchEventCount = 0;

let sendingTouchEngagements = false;

const TOUCH_BATCH_THRESHOLD = 10;

window.onbeforeunload = () => {
  if (touchEventCount > 0) {
    storage.SetItem('touchEngagementCount', touchEventCount);
  }
}

const sendGenericEngagement = () => {
  const user = store.getState()?.user;
  if (!user) return;
  touchEventCount++;
  if (touchEventCount < TOUCH_BATCH_THRESHOLD) return;
  if (sendingTouchEngagements) return;
  sendingTouchEngagements = true;
  fetch(`${process.env.REACT_APP_API_BASE_URL}/clicks`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'hero-token': user.session.accessToken
      },
      body: JSON.stringify({
        "clicksDate": new Date().toISOString(),
        "numberOfClicks": touchEventCount
      })
  }).then(() => {
    storage.RemoveItem('touchEngagementCount');
    touchEventCount -= TOUCH_BATCH_THRESHOLD;
    sendingTouchEngagements = false;
  }).catch(e => {
    sendingTouchEngagements = false;
  })
}

function App(props) {
  const href = window.location.href;
  const accessToken = href.split("?")[1];

  if (accessToken !== undefined && accessToken.split("=")[0] === "token") {
    const token = decodeURI(accessToken.split("=")[1]);
    createHeroApiClient(token).checkToken()
        .then((ssoUser) => {
          createHeroApiClient().getSSOToken(token, ssoUser.companyKey, ssoUser.ssoType, ssoUser)
              .then(tokenResponse => {
                createHeroApiClient(tokenResponse.access_token).createEngagementEvent(EngagementEvent.LOGIN)
                    .then(() => props.dispatch(beginUserSession(tokenResponse)))
              })
              .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
  }

  const location = useLocation();

  useEffect(() => {
    var OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.setExternalUserId(`${props.user.profile.id}__web`)
    })
  }, [props.user.profile.id])

  // Here we hold the current date that is been viewed by the user
  const [displayDate, setDisplayDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  // If any of the child components change the date been viewed this happens at the top level
  function changeDateByDays(daysToChangeBy) {
    setDisplayDate(
      format(addDays(new Date(displayDate), daysToChangeBy), "yyyy-MM-dd")
    );
  }

  function changeDateTo(newDateString) {
    setDisplayDate(newDateString);
  }

  const hookEngagementListeners = () => {
    if (window.fetching) {
      genericEngagementTimeout = setTimeout(hookEngagementListeners, 1000);
      return;
    }

    if (genericEngagementElements.length > 0) {
      genericEngagementElements.forEach(element => {
        if (element != null) {
          element.removeEventListener('click', sendGenericEngagement);
        }
      })
    }

    genericEngagementElements = [];

    const buttons = document.getElementsByTagName('button');
    for (let button of buttons) {
      button.addEventListener('click', sendGenericEngagement)
    }
    genericEngagementElements = genericEngagementElements.concat(...buttons)

    const anchors = document.getElementsByTagName('a');
    for (let anchor of anchors) {
      anchor.addEventListener('click', sendGenericEngagement)
    }
    genericEngagementElements = genericEngagementElements.concat(...anchors)

    const inputs = document.getElementsByTagName('input');
    for (let input of inputs) {
      input.addEventListener('click', sendGenericEngagement)
    }
    genericEngagementElements = genericEngagementElements.concat(...inputs)

    const selects = document.getElementsByTagName('select');
    for (let select of selects) {
      select.addEventListener('click', sendGenericEngagement)
    }
    genericEngagementElements = genericEngagementElements.concat(...selects)
  }

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (genericEngagementTimeout != null) {
        clearTimeout(genericEngagementTimeout);
        genericEngagementTimeout = null;
      }
      genericEngagementTimeout = setTimeout(hookEngagementListeners, 1000)
    })
  }, [location.pathname])

  useEffect(() => {
    const count = storage.GetItem('touchEngagementCount')
    touchEventCount = count ? parseInt(count) : 0;
  }, [])

  function AuthenticatedApp() {
    return (
      <DashboardLayout appConfig={props.appConfig}>
        <Switch>
          <Route path="/dashboard/:displayForDate" exact>
            <Dashboard
              forDate={displayDate}
              changeDateByDays={changeDateByDays}
              changeDateTo={changeDateTo}
            />
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard
              forDate={displayDate}
              changeDateByDays={changeDateByDays}
              changeDateTo={changeDateTo}
            />
          </Route>
          <Route path="/breathing-practices" exact>
            <BreathingPractices />
          </Route>
          <Route path="/loan-calculator" exact>
            <LoanCalculator />
          </Route>
          <Route path="/savings-calculator" exact>
            <SavingsCalculator />
          </Route>
          <Route path="/mortgage-calculator" exact>
            <MortgageCalculator />
          </Route>
          <Route path="/finance-calculators" exact>
            <FinanceCalculators />
          </Route>
          <Route path="/Calendar" exact>
            <Calendar {...props} appData={props.appData} />
          </Route>
          <Route path="/health-check" exact>
            <HealthCheck />
          </Route>
          <Route path="/health-check/:displayForDate" exact>
            <HealthCheck />
          </Route>
          <Route path="/health-calendar" exact>
            <HealthCalendar />
          </Route>
          <Route path="/health-assessment" exact>
            <HealthAssessment />
          </Route>
          <Route path="/nutrition" exact>
            <MyMeals />
          </Route>
          <Route path="/help" exact>
            <Help />
          </Route>
          <Route path="/account-help" exact>
            <AccountHelp />
          </Route>
          <Route path="/example" exact>
            <NewStyle />
          </Route>
          <Route path="/challenges" exact>
            <Challenges />
          </Route>
          <Route path="/challenges/:id/:name" exact>
            <Challenge />
          </Route>
          <Route path="/chat/:id?/:name?">
            <ChatFinder />
          </Route>
          <Route path="/meetings/:id?">
            <Video />
          </Route>
          <Route path="/article/:id" exact>
            <Article />
          </Route>
          <Route path="/user-search" exact>
            <UserSearch />
          </Route>
          <Route path="/nutrition/meals" exact>
            <MyMeals />
          </Route>
          <Route path="/nutrition/diary" exact>
            <MealDiary />
          </Route>
          <Route path="/nutrition/diary/:displayForDate" exact>
            <MealDiary />
          </Route>
          <Route path="/nutrition/calendar" exact>
            <MealCalendar />
          </Route>
          <Route path="/nutrition/meal/:mealId" exact>
            <MealDisplay />
          </Route>

          <Route path="/wellness-library/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/wellness-library" exact>
            <StoryblokContentRoot articleType='Wellness'  key="wellness-library" pageHeader="Wellness Library" />
          </Route>

          <Route path="/company-wellness" exact>
            <StoryblokContentRoot articleType='CompanyWellness' pageHeader="Company Posts" />
          </Route>

          <Route path="/fitness-library/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/fitness-library" exact>
            <StoryblokContentRoot articleType='Fitness' key="fitness-library" pageHeader="Fitness Library" />
          </Route>

          <Route path="/health-benefits/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/health-benefits" exact>
            <StoryblokContentRoot articleType='HealthBenefits' key="health-benefits" pageHeader="Health Benefits" />
          </Route>

          <Route path="/health-store/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/health-store" exact>
            <StoryblokContentRoot articleType='HealthStore' key="health-store" pageHeader="Health Store" />
          </Route>

          <Route path="/nutrition/meal-builders/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/nutrition/meal-builders" exact>
            <StoryblokContentRoot articleType='MealBuilder'  key="meal-builders" pageHeader="Meal Builders" />
          </Route>

          <Route path="/nutrition/meal-plans/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/nutrition/meal-plans" exact>
            <StoryblokContentRoot articleType='MealPlan' key="meal-plans" pageHeader="Meal Plans" />
          </Route>

          <Route path="/nutrition/meal-resources/:slug" exact>
            <StoryblokStory />
          </Route>
          <Route path="/nutrition/meal-resources" exact>
            <StoryblokContentRoot articleType='MealResource' key="meal-resources" pageHeader="Meal Resources" />
          </Route>

          <Route path="/groups" exact>
            <Groups />
          </Route>
          <Route path="/groups/:id/:name" exact>
            <Group />
          </Route>
          <Route path="/reports" exact>
            <Reports />
          </Route>
          <Route path="/hr-reports" exact>
            <HrReports />
          </Route>
          <Route path="/hr-user-management" exact>
            <HrUserManagement />
          </Route>
          <Route path="/profile" exact>
            <Profile />
          </Route>
          <Route path="/coach" exact>
            <Coach />
          </Route>
          <Route path="/goals" exact>
            <Goals />
          </Route>
          <Route path="/sources" exact>
            <Sources />
          </Route>
          <Route path="/app-connections" exact>
            <AppConnections />
          </Route>
          <Route path="/my-modules" exact>
            <Modules />
          </Route>
          <Route path="/authenticate" exact>
            <Dashboard
              forDate={displayDate}
              changeDateByDays={changeDateByDays}
              changeDateTo={changeDateTo}
            />
          </Route>
          // DEVELOPMENT ONLY - TO BE REMOVED
          <Route path="/notifications" exact>
            <Notifications />
          </Route>
          <Route path = "/user-management" exact>
            <UserManagement />
          </Route>
          
          <Route path="/" exact component={Landing} />
          <Route component={Landing} />
        </Switch>
      </DashboardLayout>
    )
  }

  function UnauthenticatedApp() {
    return (
      <Switch>
        
        <Route
          path="/myapp"
          exact
          component={props => <Login {...props} companyKey={props.companyKey} />}
        />
        <Route
          path="/login"
          exact
          component={props => <Login {...props} companyKey={props.companyKey} />}
        />
        <Route
          path="/sign-up"
          exact
          component={props => <SignUp {...props} companyKey={props.companyKey} />}
        />
        <Route path="/meet-g/:id?">
          <GuestMeeting />
          </Route>
        <Route path="/meetwv">
          <WebViewMeeting />
        </Route>
        <Route path="/ForgotPassword" exact>
            <ForgotPassword/>
        </Route>
        <Route path="/ResetPassword" exact>
          <ResetPassword/>
          </Route>
        <Route path="/" exact component={Landing} />
        <Route component={Landing} />
        
      </Switch>
    )
  }

  return props.user.session.isAuthenticated && props.user.profile.isValid ? AuthenticatedApp() : UnauthenticatedApp();
}

function mapStateToProps(state) {
  return {
    appConfig: state.appObject[dataStores.APP_CONFIG],
    user: state.user
  };
}

export default connect(mapStateToProps)(App)
